.container {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 15px;
}

.header {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 30px;
  flex-wrap: wrap;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  height: 250px;
  border-radius: 100%;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding: 30px;
}

.intro-text {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  line-height: 25px;
  font-family: Garet-Book;
  font-size: 1.6rem;
}

.content {
  grid-area: content;
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
  gap: 20px;
  grid-template-areas:
      "education experience skills";
  font-family: Garet-Book;
  font-size: 1.4rem;
  background: #2c2c2c;
  padding: 30px;
  border-radius: 20px;
}

@media (max-width: 1023px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "education"
      "experience" 
      "skills";
    max-width: 500px;
    margin: auto;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resume-link {
  font-size: 1.5rem;
}

.resume-button {
  background-color: #f4f4f5 !important;
}

.resume-link-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: center;
}

.press-text {
  max-width: 500px;
  line-height: 25px;
  font-family: Garet-Book;
  font-size: 1.6rem;
}

.press-link {
  font-family: Garet-Book;
  font-size: 1.6rem;
  color: orange;
  text-decoration: underline;
}
body {
  color: white;
}

.container {
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: 80px 1fr;
  height: 100svh;
  box-sizing: border-box;
  background: black;
}

.header {
  grid-area: header;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 30px;
  position: sticky;
  top: 0px;
  z-index: 1000;
  background: black;

}

.header.mobile {
  padding: 0px;
}

.menu-icon-wrapper {
  padding: 10px;
}

.header-logo-wrapper {
  padding: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  background: black;

}

.content-wrapper {
  grid-area: content;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 30px;
  flex-grow: 1;
}

.content-wrapper.mobile {
  padding: 0px;
  width: 100svw;
}

.footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0 10px 0;
  background: black;

}

.icon-link {
  color: #f2ad8a !important;
  font-size: 2.5rem !important;
}

.footer-text {
  font-size: 1rem;
  color: gray;
  font-family: Garet-Book;
}

.header-link {
  text-decoration: none;
  color: black;
  font-family: Garet-Book;
  &:hover {
    color: gray;
  }
}

.header-logo {
  height: 60px;
}

.menu-icon {
  font-size: 3rem !important;
}

.list-item {
  height: 60px;
}

.list-item-text {
  font-size: 2rem !important;
  text-transform: capitalize;
  color: white;
}

.menu-button-toggle {
  color: white !important;
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  gap: 15px;
}

.header {
  font-family: griffiths;
  display: flex;
  justify-content: center;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}

.iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.description-wrapper, .media-wrapper {
  width: 800px;
  margin: auto;
}

.subtitle-wrapper {
  font-style: italic;
  font-size: 1.7rem;
  line-height: 3rem;
  margin-bottom: 20px;
}

.description-wrapper {
  font-size: 2rem;
  line-height: 5rem;
}

.styleframe-header {
  font-size: 2rem;
  line-height: 5rem;
  text-align: center;
}

.embed-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; 
}

.media-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 120px;
  max-width: 1500px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.image {
  /* object-fit: cover; */
  width: 100%;
}

.styleframe-wrapper {
  padding-top: 45px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
}

.styleframe-wrapper > img {
  width: calc(50% - 15px);
}

.link {
  cursor: pointer;
  color: orange;
  text-decoration: underline;
}

.desc-wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1500px;
  justify-content: center;    
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 30px;
  padding-top: 30px;
}

.title {
  display: flex;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.description {
  display: flex;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  font-family: Garet-Book;
  font-size: 2rem;
}

.credits {
  display: flex;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-family: Garet-Book;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  .styleframe-wrapper > img {
    width: calc(100%);
  }

  .description-wrapper, .subtitle-wrapper {
    padding: 0px 20px;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    box-sizing: border-box;
  }

  .styleframe-wrapper {
    padding-top: 20px;
  }
  
  .media-wrapper {
    padding: 0px;
  }

  h1 {
    font-size: 1.5rem !important;
    padding: 0px 10px;
    margin: 0px;
  }

  h2 {
    font-size: 1.3rem !important;
    text-align: center;
  }

  .header {
    padding: 20px 0px;
  }

  .desc-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;
  }

  .description, .credits {
    font-size: 1.2rem;
  }
}
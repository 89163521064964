.container {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;  
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1500px;
  gap: 30px;
  width: 100%;
}

.container.mobile {
  gap: 10px;
  display: block;
}

.stub-card {
  content: '';
  width: 640px;
}

.card {
  position: relative;
  cursor: pointer;
}

.card:hover .card-hover {
  opacity: 100% !important;
}

.card-hover {
  position: absolute;
  height: 99%;
  width: 100%;
  opacity: 0%;
  text-align: center;
  background-color: rgb(0 0 0 / 50%);
  transition: all 0.35s ease-in-out;
}


.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
  font-family: Garet-Book;
  z-index: 100;
}

.work-gif {
  width: 640px;
}

.work-gif.mobile {
  width: 100%;
}
.container {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;  
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  max-width: 1500px;
}

.container.mobile {
  gap: 0px;
}

.work-card {
  height: 400px;
  width: 400px;
  aspect-ratio: 1 / 1;
  display: block;
  object-position: center;
  object-fit: cover;
}

.work-card.mobile {
  width: 100%;
}

.skeleton::before {
    content: '';
    position: absolute;
    inset: 0;
    animation: pulse 1.5s infinite;
}

.skeleton.loaded::before {
  content: none;
}

@keyframes pulse {
  0% {
    background-color: rgb(255, 255, 255, 0);
  }
  
  50% {
    background-color: rgb(255, 255, 255, 0.2);
  }

  100% {
    background-color: rgb(255, 255, 255, 0);
  }
  
}

.skeleton {
  position: relative;
  background-size: cover;
  background-position: center;
}

.skeleton.mobile {
  width: 100%;
}

.skeleton > img {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.skeleton.loaded > img {
  opacity: 1;
}
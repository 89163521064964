.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}

.header {
  display: flex;
  justify-content: center;
}

.iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}

.iframe-video.mobile {
  height: 100%;
}

.description-wrapper {
  flex-grow: 1;
}

.content {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.description {
  padding: 200px 0px;
  width: 800px;
  margin: auto;
  text-align: center;
  font-family: Garet-Book;
  font-size: 4rem;
  font-weight: 600;
}

.orange-text {
  color: #f2ad8a;
}

.yellow-text {
  color: #f6d249;
}

@media only screen and (max-width: 768px) {
  .description {
    padding: 100px 0px;
    width: 300px;
    font-size: 2rem;
  }
}